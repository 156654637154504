import React from 'react';

import '../assets/sass/main.scss';
const Footer = () => (
  <footer id="footer" className="wrapper style1-alt">
    <div className="inner">
      <ul className="menu">
        <li>Viso Labs Ltd</li>
        <li>27 Old Gloucester Street, London, England, WC1N 3AX</li>
        <li>Company No. 12036465</li>
        <li>&copy;2019 Viso Labs Ltd. All rights reserved.</li>
        <li>
          Design: <a href="http://html5up.net">HTML5 UP</a>{' '}
        </li>
      </ul>
    </div>
  </footer>
);

export default Footer;
